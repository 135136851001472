<template>
  <div>
    <label class="InvoicesSearch__page-label">Search Results</label>
    <base-invoice-table
      v-if="invoices.pending.length"
      class="mt-18"
      :status="'pending'"
      :user-is-client="userIsClient"
      :which-invoices="'universalSearchResults'"
    >
      <label
        class="Table__slot-content row"
        id="Testing__UniversalSearchPendingHeader"
      >
        Pending
      </label>
    </base-invoice-table>

    <base-invoice-table
      v-if="invoices.approved.length"
      class="mt-18"
      :status="'approved'"
      :user-is-client="userIsClient"
      :which-invoices="'universalSearchResults'"
    >
      <label
        class="Table__slot-content row"
        id="Testing__UniversalSearchApprovedHeader"
      >
        Approved
      </label>
    </base-invoice-table>

    <base-invoice-table
      v-if="invoices.declined.length"
      class="mt-18"
      :status="'declined'"
      :user-is-client="userIsClient"
      :which-invoices="'universalSearchResults'"
    >
      <label
        class="Table__slot-content row"
        id="Testing__UniversalSearchDeclinedHeader"
      >
        Declined
      </label>
    </base-invoice-table>

    <base-invoice-table
      v-if="invoices.paid.length"
      class="mt-18"
      :status="'paid'"
      :user-is-client="userIsClient"
      :which-invoices="'universalSearchResults'"
    >
      <label
        class="Table__slot-content row"
        id="Testing__UniversalSearchPaidHeader"
      >
        Paid
      </label>
    </base-invoice-table>

    <base-pagination
      @paginate="search"
      class="mt-22"
      :item-count="invoiceCount"
      :page="invoicePage"
      :request-limit="requestLimit"
    />
  </div>
</template>

<script>
// Helpers
import {
  Invoice,
} from '../../utils/api'
// Components
import BaseInvoiceTable from '../../components/base-invoice-table.vue'
import BasePagination from '../../components/base-pagination.vue'

export default {
  name: 'InvoiceSearchResults',

  components: {
    BaseInvoiceTable,
    BasePagination,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  created () {
    if (this.$route.query.display_id) this.display_id = this.$route.query.display_id
    if (this.$route.query.ids) this.ids = this.$route.query.ids
    if (this.$route.query.loadNumber) this.loadNumber = this.$route.query.loadNumber
  },

  beforeDestroy () {
    // clear the results on navigate away
    this.$store.commit('CLEAR_UNIVERSAL_SEARCH_RESULTS')
  },

  data () {
    return {
      display_id: null,
      ids: null,
      invoiceCount: 0,
      invoicePage: 1,
      loadNumber: null,
      requestLimit: 25
    }
  },

  computed: {
    invoices () {
      return {
        approved: this.$store.getters.universalSearchResults.invoices
          .filter(res => res.status === 'approved'),
        declined: this.$store.getters.universalSearchResults.invoices
          .filter(res => res.status === 'declined'),
        paid: this.$store.getters.universalSearchResults.invoices
          .filter(res => res.status === 'paid'),
        pending: this.$store.getters.universalSearchResults.invoices
          .filter(res => res.status === 'pending')
      }
    }
  },

  watch: {
    async display_id () {
      await this.search()
    },

    async ids () {
      await this.search()
    },

    async loadNumber () {
      await this.search()
    },

    $route () {
      // We must also watch for changes in the $route when using query instead of props
      // to keep everything reactive after created()
      this.display_id = this.$route.display_id
      this.ids = this.$route.query.ids
      this.loadNumber = this.$route.query.loadNumber
    }
  },

  methods: {
    async search (page = 1) {
      // In case user manually routed to search results (Cypress test - universalSearcH)
      // or clicking "view duplicate invoices" in an invoice-update
      // If this isn't here, you'll load the wrong invoice due to
      // pending search's invoice IDs still being in LS
      window.localStorage.removeItem('search')

      this.invoicePage = page
      this.progressStart()
      try {
        const results = {
          count: 0,
          invoices: []
        }

        // Get invoices with the specific IDs, if not null
        // Get invoices with load # (soft search) (null will just exclude the property from search)
        const backendResults = (await Invoice.queryList({
          display_id: this.display_id,
          in___id: this.ids,
          like___load_number: this.loadNumber,
          offset: (this.invoicePage - 1) * this.requestLimit
        }, this.requestLimit)).data

        results.count = backendResults.count
        this.invoiceCount = results.count
        results.invoices.push(...backendResults.rows)
        this.$store.commit('STORE_UNIVERSAL_SEARCH_RESULTS', results)
        // 2 situations:
        // 1. User searches via universal search input on invoices page
        // (where this $router.push is needed)
        // 2. User clicks on load # in the UI, such as client balances, and gets taken to
        // search-results (where this $router.push causes a NavigationDuplicated)
        if (this.$route.name !== 'search-results') this.$router.push({ name: 'search-results' })
        this.progressFinish()
      }
      catch (error) {
        this.captureSentryEvent(
          'Universal Search "Search" Error',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({
          message: 'There was an issue searching for potential matching invoices',
          title: 'Request error'
        })
      }
    },
  },
}
</script>


<style lang="sass">
@import '../../styles/invoices-search.sass'
</style>

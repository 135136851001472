var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "InvoicesSearch__page-label" }, [
        _vm._v("Search Results")
      ]),
      _vm.invoices.pending.length
        ? _c(
            "base-invoice-table",
            {
              staticClass: "mt-18",
              attrs: {
                status: "pending",
                "user-is-client": _vm.userIsClient,
                "which-invoices": "universalSearchResults"
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "Table__slot-content row",
                  attrs: { id: "Testing__UniversalSearchPendingHeader" }
                },
                [_vm._v("\n      Pending\n    ")]
              )
            ]
          )
        : _vm._e(),
      _vm.invoices.approved.length
        ? _c(
            "base-invoice-table",
            {
              staticClass: "mt-18",
              attrs: {
                status: "approved",
                "user-is-client": _vm.userIsClient,
                "which-invoices": "universalSearchResults"
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "Table__slot-content row",
                  attrs: { id: "Testing__UniversalSearchApprovedHeader" }
                },
                [_vm._v("\n      Approved\n    ")]
              )
            ]
          )
        : _vm._e(),
      _vm.invoices.declined.length
        ? _c(
            "base-invoice-table",
            {
              staticClass: "mt-18",
              attrs: {
                status: "declined",
                "user-is-client": _vm.userIsClient,
                "which-invoices": "universalSearchResults"
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "Table__slot-content row",
                  attrs: { id: "Testing__UniversalSearchDeclinedHeader" }
                },
                [_vm._v("\n      Declined\n    ")]
              )
            ]
          )
        : _vm._e(),
      _vm.invoices.paid.length
        ? _c(
            "base-invoice-table",
            {
              staticClass: "mt-18",
              attrs: {
                status: "paid",
                "user-is-client": _vm.userIsClient,
                "which-invoices": "universalSearchResults"
              }
            },
            [
              _c(
                "label",
                {
                  staticClass: "Table__slot-content row",
                  attrs: { id: "Testing__UniversalSearchPaidHeader" }
                },
                [_vm._v("\n      Paid\n    ")]
              )
            ]
          )
        : _vm._e(),
      _c("base-pagination", {
        staticClass: "mt-22",
        attrs: {
          "item-count": _vm.invoiceCount,
          page: _vm.invoicePage,
          "request-limit": _vm.requestLimit
        },
        on: { paginate: _vm.search }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }